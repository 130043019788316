import { createStore } from 'vuex'

export default createStore({
	state: {
		domain:'https://sotsuken.core-akita.ac.jp',
		xpath_title:{'it':'卒研工業系採点項目','business':'卒研商業系採点項目','design':'商業デザインポスター採点項目'},
		xpath_detail:{'it':'/item_it','business':'/item_bu','design':'/item_po'},
		xpath_do:{'it':'/input_it','business':'/input_bu','design':'/input_po'},  
		xpath_id:{'it':16,'business':18,'design':6},                      
		setting21:'/aggregate/cms/wp-json/wp/v2/setting_aggregate/21',    
		product18:'/aggregate/cms/wp-json/wp/v2/product_regist/18',       
		setting20:'/aggregate/cms/wp-json/wp/v2/setting_aggregate/20',    
		product16:'/aggregate/cms/wp-json/wp/v2/product_regist/16',       
    setting13:'/aggregate/cms/wp-json/wp/v2/setting_aggregate/13',    
    product6:'/aggregate/cms/wp-json/wp/v2/product_regist/6',         
		target:'noselect', 
		janre:'noselect',	
		title:'',
    post_title:'',
    item1:[],
		item2:[],
		item3:[],
		item4:[],
		item5:[],
		item6:[],
		item7:[],
		comment:[],
    ary21:[],
    prd18:[],
    ary20:[],
    prd16:[],
    ary13:[],
    prd6:[],
    json:[],
	},
	getters: {
	},
	mutations: {
		setTarget(state,val){state.target = val},
		setJanre(state,val){state.janre = val},
		setItem1(state,i,val){state.item1[i] = val},
		setItem2(state,i,val){state.item2[i] = val},
		setItem3(state,i,val){state.item3[i] = val},
		setItem4(state,i,val){state.item4[i] = val},
		setItem5(state,i,val){state.item5[i] = val},
		setComment(state,i,val){state.comment[i] = val},
	},
	actions: {
		setTarget({commit},val){commit('setTarget',val)},
		setJanre({commit},val){commit('setJanre',val)},
		setItem1({commit},i,val){commit('setItem1',i,val)},
		setItem2({commit},i,val){commit('setItem2',i,val)},
		setItem3({commit},i,val){commit('setItem3',i,val)},
		setItem4({commit},i,val){commit('setItem4',i,val)},
		setItem5({commit},i,val){commit('setItem5',i,val)},
		setComment({commit},i,val){commit('comment',i,val)},	
	},
	modules: {
	}
})

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  { path: '/',         name: 'home',     component: HomeView },
  { path: '/item_bu',  name: 'item_bu',  component: () => import('../views/GraduationResearch/ItemBusinessView.vue') , meta: { URL: '/aggregate/cms/wp-json/wp/v2/setting_aggregate/21' } },
  { path: '/item_it',  name: 'item_it',  component: () => import('../views/GraduationResearch/ItemItView.vue') },
  { path: '/item_po',  name: 'item_po',  component: () => import('../views/PosterContest/ItemPosterView.vue') },
  { path: '/input_bu', name: 'input_bu', component: () => import('../views/GraduationResearch/InputBusinessView.vue') },
  { path: '/input_it', name: 'input_it', component: () => import('../views/GraduationResearch/InputItView.vue') },
  { path: '/input_po', name: 'input_po', component: () => import('../views/PosterContest/InputPosterView.vue') },
  { path: '/post',     name: 'post',     component: () => import('../views/GraduationResearch/PostView.vue') },
  { path: '/success',  name: 'success',  component: () => import('../views/SuccessView.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

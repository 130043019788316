<template>
  <header>
    <div class="h_hamburger">
      <div class="hbg_grd">
        <div class="h_grd_wrap">
          <div class="h_logo">
            <a href="/">
              <img src="@/assets/hlogo.png" alt="アグリゲートロゴ" />
            </a>
          </div>

        </div>
      </div>

    </div>

  </header>

  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<style>
  /* Template ------------------------------------ */
  :root{
    --bgc:linear-gradient(90deg, #A4D1DB 0%, #AEBEE3 40%, #B3C5E6 100%);
    --c1:#A4B6D8;
    --c2:#3A3A3A;
    --c3:#758CC4;
    --c4:#F6F9FF;
    --c5:#B9B9B9;
    --err:#C47575;
    --white:#fff;
    --black-1:#535353;
    --sp-width:90%;
    --pc-width:108rem;
  }
  *{
    margin: 0;
    padding: 0;
    border: 0;
  }
  html{
    font-size: 62.5%;
  }
  body{
    font-size: 1.6rem;
  }
  ul{
    list-style: none;
  }
  a{
    text-decoration: none;
  }
  /* Common -------------------------------------- */
  .hg_icon::before,
  .hg_icon::after{
    content: "";
  }
  .hbg_grd,
  .h_hbg_line span,
  .hg_icon,
  .hg_all_score{
    position: relative;
  }
  .hbg_gmenu,
  .hg_icon::before,
  .hg_icon::after,
  .hg_all_score::before,
  .hg_all_score::after{
    position: absolute;
  }
  .h_grd_wrap,
  .hbg_gmenu li,
  .page_wrap,
  .page_wrap span{
    margin: 0 auto;
  }
  .h_logo img,
  .h_hbg_line span,
  .hg_icon::before,
  .hg_icon::after,
  .page_wrap span{
    display: block;
  }
  .hbg_grd,
  .h_grd_wrap,
  .h_hbg,
  .h_flex,
  .hbg_gmenu li a,
  .h_page,
  .page_wrap,
  .page_wrap a{
    display: flex;
  }
  .hbg_grd,
  .h_grd_wrap,
  .page_wrap{
    justify-content: space-between;
  }
  .hbg_grd,
  .h_grd_wrap,
  .h_hbg,
  .hbg_gmenu li a,
  .h_page,
  .page_wrap a{
    align-items: center;
  }
  .hbg_gmenu,
  .hbg_gmenu li a{
    width: 100%;
  }
  .h_grd_wrap,
  .page_wrap{
    width: 32rem;
  }
  .h_hbg_line span,
  .hbg_gmenu,
  .hbg_gmenu li a{
    transition: all .3s ease 0s;
  }

  /* Header -------------------------------------- */
  .hbg_grd{
    height: 6rem;
    z-index: 101;
    background-image: var(--bgc);
  }
  .h_grd_wrap{
    max-width: 108rem;
  }
  .h_logo img{
    width: 12rem;
  }

  @media(min-width:600px){
    .h_grd_wrap,
    .page_wrap{
      width: 80%;
    }
  }
  @media(min-width:1200px){
    .h_grd_wrap,
    .page_wrap{
      width: 108rem;
    }
  }
</style>

<script>
export default {
  data(){
    return{
    }
  },
  methods:{
    oniconclick(){
      let hIcon = document.querySelector('.h_hbg');
      hIcon.onclick = function(){
        this.classList.toggle('h_active');
      }
    },
  },                             
}
</script>


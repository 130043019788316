<template>
	<div class="all_top">
		<div class="home">
			<div class="home_logo">
				<img src="../assets/mlogo.png" alt="ホームロゴ" />
			</div>

			<div class="home_flx">
				<div id="homeSelect" class="home_select">
					<p class="home_select_txt">区分を選んでください</p>
					<div class="home_select_arrow">
						<select name="proSelect" class="home_select_box" v-model="target" >
							<option value="noselect">未選択です</option>
							<option value="student">学生（一般）</option>
							<option value="judge">学生（審査員）</option>
							<option value="pro">企業の方</option>
							<option value="teacher">先生</option>
							<option value="judge_teacher">先生（審査員）</option>
							<option value="principal">校長先生</option>
						</select>
					</div>
				</div>
				<div id="homeSelect" class="home_select">
					<p class="home_select_txt">イベントを選んでください</p>
					<div class="home_select_arrow">
						<select name="proSelect" class="home_select_box" v-model="janre" value="未選択です">
							<option value="noselect" >未選択です</option>
							<option value="design">商業デザインポスター</option>
							<option value="it">卒業研究（工業系）</option>
							<option value="business">卒業研究（商業系）</option>
						</select>
					</div>
				</div>
			</div>
			<a href="#" class="btn_default home_btn" @click.prevent="onNextPage()">
				採点項目へ
			</a>
		</div>
		<footer>&copy;306物語 All Rights Reserved.</footer>
	</div>
</template>

<script>
export default {
	data(){
		return{
			target:'noselect',
			janre:'noselect',
			
		}
	},
	methods:{
		onNextPage(){
			this.$store.state.target = this.target;
			this.$store.state.janre = this.janre;
			if( this.janre != 'noselect' && this.target != 'noselect' ){
				this.$router.push(this.$store.state.xpath_detail[this.janre]);
			}
			else{
				if( this.target == 'noselect' ){
					alert('区分を選択しておりません');
				}
				if( this.janre == 'noselect' ){
					alert('イベントを選択しておりません');
				}
			}
		}
	}
}
</script>

<style>
	/* default ------------------------------------- */
	:root{
		--bgc:linear-gradient(90deg, #A4D1DB 0%, #AEBEE3 40%, #B3C5E6 100%);
		--c1:#A4B6D8;
		--c2:#3A3A3A;
		--c3:#758CC4;
		--c4:#F6F9FF;
		--c5:#B9B9B9;
		--c6:#A4D1DB;
		--err:#C47575;
		--white:#fff;
		--black:#535353;
		--sp-width:85%;
		--pc-width:108rem;
	}
	*{
		margin: 0;
		padding: 0;
		border: 0;
	}
	html{
		font-size: 62.5%;
	}
	body{
		font-size: 1.6rem;
	}
	ul{
		list-style: none;
	}
	a{
		text-decoration: none;
	}
	/* common -------------------------------------- */
	.btn_default{
		box-sizing: border-box;
		display:flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		border-radius: .4rem;
		width: 24rem;
		height: 6rem;
		color: var(--c4);
		background-color: var(--c1);
		font-size: 2rem;
		transition: all .3s ease 0s;
	}
	.btn_default::after{
		content: "";
		position: relative;
		top: -.1rem;
		display: block;
		margin-left: 3rem;
		width: 1rem;
		height: 1rem;
		border-top: .2rem solid #fff;
		border-right: .2rem solid #fff;
		transform: rotate(45deg);
	}
	.btn_default:hover{
		background-color: var(--c6);
	}
	header{
		display: none;
	}
	/* home ---------------------------------------- */
	.all_top{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100vh;
	}
	.home{
		margin: auto;
		width: 90%;
	}
	.home_logo{
		display: flex;
		align-items: center;
		margin-bottom: 6rem;
	}
	.home_logo img{
		display: block;
		margin: 0 auto;
		width: 30rem;
	}
	.home_select:first-of-type{
		margin-bottom: 2rem;
	}
	.home_select_txt{
		margin-bottom: 1rem;
		font-size: 2rem;
	}
	.home_select_box{
		text-align: center;
		box-sizing: border-box;
		border-radius: .4rem;
		border: .2rem solid var(--black);
		width: 24rem;
		height: 5rem;
		background-color: var(--white);
		font-size: 1.8rem;
	}
	.home_btn{
		margin-top: 6rem;
	}
	/* footer -------------------------------------- */
	footer{
		padding-bottom: 1rem;
		width: 100vw;
	}
	address{
		margin: 0 auto;
	}
	/* responsiv ----------------------------------- */
	@media( min-width: 1200px ){
		.home_logo img,
		.home_flx{
			width: 60rem;
		}
		.home_flx{
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}
	}
</style>
